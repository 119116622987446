// @file appBarAccount store
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppBarAccountStore = defineStore('appBarAccount', () => {
  const xUserMenu = ref(false)
  // Getters

  // Actions
  function showUserMenu(): void {
    xUserMenu.value = true
  }

  function hideUserMenu(): void {
    xUserMenu.value = false
  }

  return {
    // State
    xUserMenu,

    // Getters

    // Actions
    showUserMenu,
    hideUserMenu,
  }
})
